<template>
  <div class="content" v-if="tournament">
    <div class="title-actions">
      <h5 class="title">{{ tournament.name }} - {{$t('division')}} - {{ division.name }}</h5>
      <div class="btns">
        <b-form-input v-model="filter" :placeholder="$t('search')" autofocus />
        <b-button variant="info" @click="create">
          <i class="fa fa-plus"></i>
        </b-button>
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <b-table striped bordered :items="seasons" :fields="columns" :filter="filter">
      <template slot="name" slot-scope="row">
        <img :src="row.item.cup.image" :alt="row.item.cup.name" class="img-fluid img-thumbnail mr-3" style="max-width:40px;">
        <span>{{ row.item.name }}</span>
      </template>
      <template slot="actions" slot-scope="row">
        <div class="w-100 text-center">
          <b-button class="mx-2" variant="success" @click="edit(row.item)">
            <i class=" px-1 fa fa-pencil"></i>
          </b-button>
          <b-button class="mx-2" variant="success" @click="changeCup(row.item)">
            <i class=" px-1 fa fa-trophy"></i>
          </b-button>
        </div>
      </template>
    </b-table>
    <b-form @submit.prevent="save" data-vv-scope="save">
      <b-modal id="modalAddEdit" :title="title">
        <div class="form-group">
          <label for="name">{{ $t('name') }}</label>
          <b-form-input v-model="season.name" name="name" id="name" v-validate="'required|max:50'" :state="validateState('name')" />
          <span class="error-inputs">{{ errors.first('name') }}</span>
        </div>      
        <template slot="modal-footer">
          <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalAddEdit')">{{ $t('cancel') }}</b-button>
          <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
        </template>
      </b-modal>
    </b-form>
    <b-form @submit.prevent="saveCup()" data-vv-scope="saveCup">
      <b-modal id="modalAddAddCup" :title="`${season.name} :: ${$t('changeCup')}`">
        <div class="form-group">
          <label for="file">{{ $t('findImage') }} - <small class="text-muted">*.png | 310 x 554px</small></label>
          <div class="custom-file" :class="validateState('files') ? 'is-valid' : 'is-invalid'">
            <input type="file" ref="inputFile" class="custom-file-input" id="file" :lang="lang" @change="imagePreview($event)" v-validate="'image|ext:png|size:1024'" accept=".png" name="files" :data-vv-as="$t('image') | toLowerCase">
            <label class="custom-file-label" ref="lblFileName" for="file">{{ $t('findImage') }}</label>
          </div>
          <div class="text-center mt-3">
            <img :src="cup.image" alt="Vista previa" class="img-fluid img-thumbnail">
          </div>
        </div>
        <div class="form-group">
          <label for="end">{{ $t('name') }}</label>
          <b-form-input v-model="cup.name" name="name" id="name" v-validate="'required|max:50'" :state="validateState('name')" />
          <span class="error-inputs">{{ errors.first('name') }}</span>
        </div>      
        <template slot="modal-footer">
          <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalAddAddCup')">{{ $t('cancel') }}</b-button>
          <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      filter: '',
      seasons: [],
      season: {},
      columns: [
        { key: 'name', label: this.$t('name'), sortable: true },
        { key: 'created_at', label: this.$t('createdAgo'), sortable: true },
        { key: 'actions', label: '' }
      ],
      title: '',
      tournament: null,
      division: null,
      cup: {},
      file: [],
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console'
    ])
  },
    mounted() {
    this.fetchData()
  },
  methods: {
    imagePreview (e) {
      const input = e.target  
      this.file = input.files[0]
      const _self = this
      if (input.files && input.files[0]) {
        _self.$refs.lblFileName.innerHTML = this.file.name
        var reader = new FileReader()
        reader.onload = function (e) {
          _self.cup.image = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        _self.file = []
        _self.$refs.lblFileName.innerHTML =  _self.$t('findImage')
      }
    },
    changeCup(season) {
      this.season = JSON.parse(JSON.stringify(season))
      this.cup = this.season.cup
      this.$root.$emit('bv::show::modal', 'modalAddAddCup')
    },
    saveCup() {
      this.$validator.validateAll('saveCup').then(result => {
        if (result) {
          const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/division/${this.division.id}/seasons/${this.season.id}/add-cup`
          const payload = new FormData()
          payload.append('image', this.file)
          payload.append('name', this.cup.name)
          this.$axios.post(path, payload).then(response => {
            this.cup = {}
            this.seasons = this.seasons.map(s => {
              if (s.id == this.season.id) {
                s.cup = response.data.data
              }
              return s
            })
            this.$toastr.success(this.$t('updatedInfo'))
            this.$root.$emit('bv::hide::modal', 'modalAddAddCup')
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    create () {
      this.season = {}
      this.title = this.$t('create')
      this.$root.$emit('bv::show::modal', 'modalAddEdit')
    },
    edit (season) {
      this.season = JSON.parse(JSON.stringify(season))
      this.title = this.$t('edit')
      this.$root.$emit('bv::show::modal', 'modalAddEdit')
    },
    save () {
      this.$validator.validateAll('save').then(result => {
        if (result) {
          const payload = this.season
          if (!this.season.id) {
            const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/division/${this.division.id}/seasons/store`
            this.$axios.post(path, payload).then(response => {
              this.seasons.unshift(response.data.data)
              this.$root.$emit('bv::hide::modal', 'modalAddEdit')
            })
          } else {
            const path = `auth/${this.lang}/${this.console}/tournament/${this.tournament.id}/division/${this.division.id}/seasons/${this.season.id}/update`
            this.$axios.put(path, payload).then(() => {
              this.seasons = this.seasons.map(season => {
                if (season.id === this.season.id) {
                  season.name = this.season.name
                }
                return season
              })
              this.$root.$emit('bv::hide::modal', 'modalAddEdit')
            })
          }
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    fetchData () {
      const tournamentId = this.$route.params.tournamentId
      const divisionId = this.$route.params.divisionId
      const path = `auth/${this.lang}/${this.console}/tournament/${tournamentId}/division/${divisionId}/seasons`
      this.$axios.get(path).then((response) => {
        this.seasons = response.data.seasons
        this.tournament = response.data.tournament
        this.division = response.data.division
        document.title = `${this.tournament.name} - ${this.$t('division')} - ${this.division.name}`
      }).catch(() => {
        this.$router.go(-1)
      })
    }
  }
}
</script>
